<template>
  <div class="main-box">

    <el-card class="box-card" shadow="never">
      <el-row :gutter="20">
        <el-col :span="24">
          <!--
          <el-select :popper-append-to-body="false" v-model="queryInfo.businessGroups"  class="input" clearable placeholder="企业部门">
            <el-option v-for="item in inputDicts.businessGroupList" :key="item.code" :label="item.labels" :value="item.code"></el-option>
          </el-select>
          -->
          <el-select placeholder="用户状态" v-model="queryInfo.status" class="input" clearable>
            <el-option label="启用" value="normal"></el-option>
            <el-option label="禁用" value="disable"></el-option>
          </el-select>
          <el-select placeholder="用户类型" v-model="queryInfo.userType" class="input" clearable>
            <el-option label="普通用户" value="user"></el-option>
            <el-option label="管理人员" value="manager"></el-option>
          </el-select>
          <el-input placeholder="登录账户" v-model="queryInfo.userName" clearable class="input"></el-input>
          <el-input placeholder="用户名" v-model="queryInfo.fullName" clearable class="input"></el-input>
          <el-button type="primary" @click="backNumOne">查询</el-button>
          <el-button type="primary" @click="addDialog">添加用户</el-button>
        </el-col>
      </el-row>

      <el-dialog title="添加用户" :visible.sync="addVisible" :close-on-click-modal="false" @close="deleteData" width="600px">
        <el-form ref="form" :model="addData" label-width="80px" :rules="rules">
          <!--
          <el-form-item label="企业部门" prop="businessGroups" >
            <el-select v-model="addData.businessGroups"   >
              <el-option v-for="item in inputDicts.businessGroupList" :key="item.code" :label="item.labels" :value="item.code" ></el-option>
            </el-select>
          </el-form-item>
          -->
          <el-form-item label="账号" prop="userName">
            <el-input v-model="addData.userName" class="input"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="fullName">
            <el-input v-model="addData.fullName" class="input"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="addData.password" class="input" show-password></el-input>
          </el-form-item>
          <el-form-item label="用户类型" prop="userType">
            <el-select v-model="addData.userType" placeholder="请选择用户类型">
              <el-option label="普通用户" value="user"></el-option>
              <el-option label="管理人员" value="manager"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户角色" prop="role">
            <el-select v-model="addData.rolesToAdd" multiple placeholder="角色" class="input-large">
              <el-option v-for="item in inputDicts.roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="addVisible=false">取消</el-button>
            <el-button type="primary" @click="addUser">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改用户" :visible.sync="editVisible" :close-on-click-modal="false" width="600px">
        <el-form ref="form" :model="editData" label-width="80px" :rules="rules">
          <el-form-item label="账号" prop="userName">
            <el-input v-model="editData.userName" class="input" readonly></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="fullName">
            <el-input v-model="editData.fullName" class="input"></el-input>
          </el-form-item>
          <el-form-item label="用户类型" prop="userType">
            <el-select v-model="editData.userType" placeholder="请选择用户类型">
              <el-option label="普通用户" value="user"></el-option>
              <el-option label="管理人员" value="manager"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户角色" prop="role">
            <el-select v-model="editData.rolesToAdd" multiple class="input-large">
              <el-option v-for="item in inputDicts.roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="editVisible=false">取消</el-button>
            <el-button type="primary" @click="editUser">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改密码" :visible.sync="pwdVisible" :close-on-click-modal="false" width="380px">
        <el-form :model="pwdData" label-width="80px" :rules="rules">
          <el-form-item label="账号">
            <el-input v-model="pwdData.userName" disabled class="input" readonly></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="pwdData.fullName" disabled class="input" readonly></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="pwdData.password" class="input" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input v-model="pwdData.passwordConfirm" class="input" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="pwdVisible=false">取消</el-button>
            <el-button type="primary" @click="editPassword">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-table :data="userList" border stripe>
        <!--
        <el-table-column label="企业部门" prop="businessGroups" :formatter="businessGroupsFormatter" ></el-table-column>
        -->
        <el-table-column label="账号" prop="userName"></el-table-column>
        <el-table-column label="姓名" prop="fullName"></el-table-column>
        <el-table-column label="状态" width="100px" prop="status" :formatter="statusFormatter"></el-table-column>
        <el-table-column label="用户类型" width="100px" prop="userType" :formatter="userTypeFormatter"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="修改时间" prop="updateTime"></el-table-column>
        <el-table-column label="角色" prop="roleNames" width="500px"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button size="mini" @click="pwdDialog(scope.row)">修改密码</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status=='normal'" @click="statusOpen(scope.row)"><i class="el-icon-video-pause"></i>禁用</el-button>
            <el-button type="success" size="mini" v-if="scope.row.status=='disable'" @click="statusClick(scope.row)"><i class="el-icon-video-play"></i>启用</el-button>
            <el-button type="primary" size="mini" v-if="scope.row.status=='normal'" @click="editDialog(scope.row)"><i class="el-icon-edit"></i> 编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page-box">
        <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" :current-page.sync="currentPage" @current-change="currentChange"></el-pagination>
      </div>

    </el-card>
  </div>

</template>

<script>
export default {
  name: "User",
  created() {
    this.getUserList()
  },
  data() {
    return {

      queryInfo: {
        businessGroups: '',
        userName: '',
        pageNo: 1,
        pageSize: 10,
        status: '',
        userType: '',
        rolesToAdd: [],
      },
      addData: {
        businessGroups: '',
        userName: '',
        fullName: '',
        password: '',
        userType: '',
        rolesToAdd: []
      },
      editData: {
        id: '',
        userName: '',
        fullName: '',
        userType: '',
        businessGroup: '',
        rolesToAdd: []
      },
      pwdData: {
        id: '',
        userName: '',
        fullName: '',
        password: '',
        passwordConfirm: ''
      },
      rules: {
        userName: [{required: true, message: '请输入账户名', trigger: 'blur'}],
        fullName: [{required: true, message: '请输入名称', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        status: [{required: true, message: '请选择状态', trigger: 'blur'}],
        userType: [{required: true, message: '请选择用户类型', trigger: 'blur'}]
      },
      inputDicts: {
        roleList: [],
        businessGroupList: []
      },
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userList: [],
      addVisible: false,
      editVisible: false,
      pwdVisible: false,
      status: '',
      getRoles: '',
      getRoles2: ''
    }
  },
  methods: {
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.getUserList();
    },
    async getUserList() {
      const {data: res} = await this.$axios.post('user/selectList', this.queryInfo);
      if (res.code !== 0) {
        return this.$message.error('获取用户列表失败！')
      }
      this.userList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },

    async addUser() {
      const {data: res} = await this.$axios.post('/user/addUser', this.addData);
      if (this.addData.businessGroups == null) {
        return this.$message.error("请选择企业部门");
      }
      if (this.addData.password.length < 6) {
        return this.$message.error("密码不足6位长");
      }

      if (this.addData.rolesToAdd == null && this.addData.rolesToAdd.length < 1) {
        return this.$message.error('至少选择一个角色');
      }

      if (res.code !== 0) {
        return this.$message.error(res.message);
      } else {
        this.$message.success('添加成功！');
        this.addVisible = false;
        this.getUserList();
      }

    },

    async editUser() {
      const {data: res} = await this.$axios.post('/user/editUser', {
        id: this.editData.id,
        userName: this.editData.userName,
        fullName: this.editData.fullName,
        userType: this.editData.userType,
        rolesToAdd: this.editData.rolesToAdd,
        businessGroups: this.editData.businessGroups
      });

      if (res.code !== 0) {
        return this.$message.error('修改失败！');
      } else {
        this.$message.success('修改成功！');
        this.editVisible = false;
        this.getUserList();
      }

    },
    async editPassword() {
      if (this.pwdData.password == '') {
        this.$message.error("密码未输入");
        return;
      }
      if (this.pwdData.password.length < 6) {
        this.$message.error("密码不足6位长");
        return;
      }
      if (this.pwdData.password != this.pwdData.passwordConfirm) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      const {data: res} = await this.$axios.post('/user/editPassword', {
        id: this.pwdData.id,
        password: this.pwdData.password
      });
      if (res.code !== 0) {
        return this.$message.error('修改失败！');
      }
      this.$message.success('修改成功！');
      this.pwdData.password = '';
      this.pwdData.passwordConfirm = '';
      this.pwdVisible = false;
      this.getUserList();


    },
    async statusClick(row) {
      const {data: res} = await this.$axios.get('/user/setStatus', {params: {id: row.id}});
      if (res.code !== 0) {
        if (row.status == 'normal') {
          return this.$message.error('禁用失败！');
        } else {
          return this.$message.error('启用失败！');
        }
      } else {
        if (row.status == 'normal') {
          this.getUserList();
          return this.$message.success('禁用成功！');
        } else {
          this.getUserList();
          return this.$message.success('启用成功！');
        }

      }
    },
    async getInputDict() {
      const {data: res} = await this.$axios.get('user/getInputDicts');
      if (res.code !== 0) {
        return this.$message.error('获取失败！');
      }
      this.inputDicts = res.data;
    },
    statusFormatter(row, column, cellValue) {
      if (cellValue == 'normal') {
        return '正常';
      } else if (cellValue == 'disable') {
        return '禁用';
      }
      return cellValue;
    },
    userTypeFormatter(row, column, cellValue) {
      if (cellValue == 'user') {
        return '普通用户';
      } else if (cellValue == 'manager') {
        return '管理人员';
      }
      return cellValue;
    },
    addDialog() {

      this.addVisible = true;
    },
    editDialog(row) {
      this.editData.id = row.id;
      this.editData.userName = row.userName;
      this.editData.fullName = row.fullName;
      this.editData.password = row.password;
      this.editData.userType = row.userType;
      this.editData.rolesToAdd = [];
      for (var i = 0; i < row.roles.length; i++) {
        this.editData.rolesToAdd.push(row.roles[i].id)
      }
      this.editVisible = true;
    },
    pwdDialog(row) {
      this.pwdData.id = row.id;
      this.pwdData.userName = row.userName;
      this.pwdData.fullName = row.fullName;
      this.pwdVisible = true;
    },
    statusOpen(row) {
      this.$confirm('是否确认禁用' + row.fullName + '?', '禁用提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        callback: action => {
          if (action === 'confirm') {
            this.statusClick(row);
          } else {
            console.log('按下 取消')
          }
        }
      })

    },
    deleteData() {
      this.addData.fullName = '';
      this.addData.password = '';
      this.addData.userName = '';
      this.addData.userType = '';
    },
    backNumOne() {
      this.queryInfo.pageNo = 1;
      this.currentPage = 1;
      this.getUserList();

    }

  },
  mounted() {
    this.getInputDict();
    this.backNumOne();
  }
}
</script>

<style scoped>
.el-table {
  margin-top: 15px;
  font-size: 12px;
}

.page-box {
  text-align: right;
  padding-top: 5px;
}

.input {
  width: 200px;
  margin-right: 10px;
}

.input-large {
  width: 480px;
}

</style>
